<template>
      <div class="ks-page-header">
            <h3>HOME PAGE</h3>
     </div>
    <div class="ks-content flex-1">
     <div class=" home_container scroll">
          <div class="d-flex flex-column ">
               <div class="d-flex justify-content-around mt-5 con1">
                    <div class="d-flex flex-column con">
                         <div>
                         <h4 class="text-center">Human Resources Information System</h4> 
                         </div>
                         <a href="https://hris.ardentnetworks.com.ph/"  target="new_tab()">
                              <img src="@/assets/hris.jpg" alt="hris website image">

                         </a>

                         <a href="https://hris.ardentnetworks.com.ph/" class="text-center"  target="new_tab()">https://hris.ardentnetworks.com.ph/</a>
                    
                    </div>
                    <div class="d-flex flex-column con">
                         <div>
                              <h4 class="text-center">Data Management System </h4>
                         </div>
                         <a href="https://dms.ardentnetworks.com.ph/"  target="new_tab()">
                              <img src="@/assets/dms.jpg" alt="hris website image" >
                         </a>
                         <a href="https://dms.ardentnetworks.com.ph/" class="text-center"  target="new_tab()">https://dms.ardentnetworks.com.ph/</a>

                    
                    </div>
               </div>
               <div class="d-flex justify-content-around mt-5 con1">
                    <div class="d-flex flex-column con">
                         <div>
                              <h4 class="text-center">Check Requisition Approval</h4>
                         </div>
                         <a href="https://crf.ardentnetworks.com.ph/"  target="new_tab()">
                              <img src="@/assets/crf.jpg" alt="hris website image" >
                         </a>
                         <a href="https://crf.ardentnetworks.com.ph/" class="text-center"  target="new_tab()">https://crf.ardentnetworks.com.ph/</a>

                    </div>
                    <div class="d-flex flex-column con">
                         <div>
                              <h4 class="text-center">Marketing Initiative Approval  </h4>
                         </div>
                         <a href="https://miaf.ardentnetworks.com.ph/"  target="new_tab()">
                              <img src="@/assets/miaf.jpg" alt="hris website image" >
                         </a>
                         <a href="https://miaf.ardentnetworks.com.ph/" class="text-center" target="new_tab()">https://miaf.ardentnetworks.com.ph/</a>

                    </div>
               </div>
               <div class="d-flex justify-content-around mt-5 con1">
                    <div class="d-flex flex-column con">
                    <div>
                          <h4 class="text-center">Online Mobile Office</h4>
                         </div>
                         <a href="http://ewap.ardentnetworks.com.ph/mobile_office/"  target="new_tab()">
                              <img src="@/assets/ewap.jpg" alt="hris website image" >
                         </a>
                         <a href="http://ewap.ardentnetworks.com.ph/mobile_office/" class="text-center"  target="new_tab()">http://ewap.ardentnetworks.com.ph/mobile_office/</a>
                    </div>
               </div>
          
        

          </div>
     </div>   


      </div>

 
 
     <!-- <iframe :src="`https://www.facebook.com/`" class="flex-1"></iframe> -->
</template>
<style scoped>
*{
     margin: 0;
     padding: 0;
     box-sizing: border-box;
}

img{
     height: 250px;
     width: 500px;
     border-radius: 20px;
}
.con {
     margin: 20px 0 20px 0 ;
}
.last{
     margin-bottom: 100px;
}
.home_container{
     height: 800px;
}
@media screen and (max-width: 890px) {
     .con1 {
          flex-direction: column !important;
          justify-content: center !important;
     }
     .con1 a {
          text-align: center;
     }
}
/* .justify-content-around{
     justify-content: space-around !important;
} */
/* .head{
     height: 50%;
}
.body{
     height: 30%;
} */
</style>